import { configureStore } from '@reduxjs/toolkit';
import { StoreKeys } from '@/configs/StoreKeys';
import questionnaireTemplateReducer from './slices/questionnaireTemplateSlice';
import appReducer from './slices/appSlice';

export const store = configureStore({
	reducer: {
		[StoreKeys.app]: appReducer,
		[StoreKeys.questionnaireTemplate]: questionnaireTemplateReducer,
	},
	devTools: import.meta.env.DEV,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
