import { flatten, isEqual } from 'lodash';
import { AnswerTemplate, QuestionTemplate } from '@/api/PreQuestionnaireApi';
import { PayoutStatus, TransactionOperation } from '@/api/PanelistApi';
import { Region, RegionType } from '@/api/RegionsApi';
import { Language } from '@/types/types';
import { MailTemplateType } from '@/api/MailTemplateApi';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';

const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%._*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

/**
 *
 * @param password one capital one number one samll speciall and at least 8
 * @returns
 */
export function validatePassword(password: string) {
  return PASSWORD_REGEX.test(password);
}

export function randomDate(start: Date, end: Date) {
  return moment(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  ).toDate();
}

export function formatDateForInput(date: moment.MomentInput) {
  return moment(date).format('YYYY-MM-DD');
}

export function formatDateForPresentation(
  date: moment.MomentInput = new Date()
) {
  return moment(date).format('DD/MM/YYYY');
}

export function formatTimeForPresentation(
  date: moment.MomentInput = new Date()
) {
  return moment(date).format('HH:mm');
}

export function formatDateForAPI(date: moment.MomentInput) {
  return `${formatDateForInput(date)} ${formatTimeForPresentation(date)}`;
}

export function formatExactDate(date: moment.MomentInput = new Date()) {
  return `${formatDateForPresentation(date)}, ${formatTimeForPresentation(
    date
  )}`;
}

export function sortByDate(
  dateA: moment.MomentInput,
  dateB: moment.MomentInput,
  order: 'asc' | 'desc' = 'asc'
) {
  const first = moment(dateA).valueOf(),
    second = moment(dateB).valueOf();

  return order === 'asc' ? first - second : second - first;
}

export function sortByName(
  nameA: string,
  nameB: string,
  order: 'asc' | 'desc' = 'asc'
) {
  return order === 'asc'
    ? nameA.localeCompare(nameB)
    : nameB.localeCompare(nameA);
}

export function incrementDate(date: moment.MomentInput) {
  return moment(date).add(1, 'day').toDate();
}

/**
 *
 * @param percent
 */
export function setColorsPerPercent(percent: number) {
  if (percent >= 70) return ['#34c38f', 'success'];
  else if (percent >= 40) return ['#f1b44c', 'warning'];
  else return ['#f46a6a', 'danger'];
}

export function getQuestionChanges(
  oldQuestions: QuestionTemplate[],
  newQuestions: QuestionTemplate[]
) {
  const addedQuestions = newQuestions.filter(
    (newQ) => !oldQuestions.some((oldQ) => oldQ.id === newQ.id)
  );

  const removedQuestions = oldQuestions.filter(
    (oldQ) => !newQuestions.some((newQ) => newQ.id === oldQ.id)
  );

  const commonQuestions = newQuestions.filter((newQ) =>
    oldQuestions.some((oldQ) => oldQ.id === newQ.id)
  );

  const editedQuestions: QuestionTemplate[] = commonQuestions.filter(
    (newQ) =>
      !isEqual(
        newQ,
        oldQuestions.find((oldQ) => newQ.id === oldQ.id)
      )
  );

  return {
    addedQuestions,
    removedQuestions,
    editedQuestions,
  };
}

export function getAnswerChanges(
  oldAnswers: AnswerTemplate[],
  newAnswers: AnswerTemplate[]
) {
  const addedAnswers = newAnswers.filter(
    (newA) => !oldAnswers.some((oldQ) => oldQ.id === newA.id)
  );

  const removedAnswers = oldAnswers.filter(
    (oldA) => !newAnswers.some((newA) => newA.id === oldA.id)
  );

  const commonAnswers = newAnswers.filter((newA) =>
    oldAnswers.some((oldA) => oldA.id === newA.id)
  );

  const editedAnswers = commonAnswers.filter(
    (newA) =>
      !isEqual(
        newA,
        oldAnswers.find((oldA) => newA.id === oldA.id)
      )
  );

  return {
    addedAnswers,
    removedAnswers,
    editedAnswers,
  };
}

export function handleUnload(e: BeforeUnloadEvent) {
  e.preventDefault();
  e.returnValue = '';
}

export function mapTransactionStatusToName(status: PayoutStatus): string {
  switch (status) {
    case PayoutStatus.PENDING:
      return 'Oczekiwanie';
    case PayoutStatus.CANCELLED:
      return 'Anulowane';
    case PayoutStatus.PAID:
      return 'Wypłacone';
  }
}

export function formatPointsName(points = 0) {
  if (points === 1) return 'punkt';
  if (points < 5) return 'punkty';
  return 'punktów';
}

export const formatPrice = (
  value?: number,
  opts?: Intl.NumberFormatOptions
) => {
  if (value === undefined) return '-';

  const formatter = new Intl.NumberFormat('PL', {
    style: 'currency',
    currency: 'PLN',
    currencyDisplay: 'code',
    maximumFractionDigits: !value ? 0 : undefined,
    ...opts,
  });

  return formatter.format(value);
};

export const ID_REGEX =
  /[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
  return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
  switch (fileType) {
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'csv':
      return 'text/csv';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
}

export function getPostalCodesFromText(text: string) {
  return flatten([...text.matchAll(/\d{2}-\d{3}/g)]);
}

export function getRegionType(region: Region) {
  if (region.city) return RegionType.CITY;
  if (region.county) return RegionType.COUNTY;
  return RegionType.PROVINCE;
}

export function formatSecondsToMin(duration: number) {
  let hrs = ~~(duration / 3600);
  let mins = ~~((duration % 3600) / 60);
  let secs = ~~duration % 60;

  let ret = '';

  if (hrs > 0) ret += '' + hrs + ':' + (mins < 10 ? '0' : '');

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
}

export function fileSizeToMB(size?: number | string) {
  return Math.round((Number(size) / 1000000 + Number.EPSILON) * 100) / 100;
}

export function isDefaultLanguage(language: Language): language is 'PL' {
  return language === APP_CONFIG.DEFAULT_LANGUAGE;
}

export function mapMailTemplateTypeToName(type: MailTemplateType) {
  switch (type) {
    case MailTemplateType.RESET_PASSWORD:
      return 'Resetowanie hasła';
    case MailTemplateType.PASSWORD_CHANGED:
      return 'Hasło zostało zmienione';
    case MailTemplateType.ADMIN_NEW_CLIENT:
      return 'Nowy klient w systemie';
    case MailTemplateType.PANELIST_ACTIVE:
      return 'Panelista aktywny';
    case MailTemplateType.PANELIST_REGISTER:
      return 'Rejestracja Panelsity';
    case MailTemplateType.PANELIST_PREQUESTIONNAIRE_REMINDER:
      return 'Przypomnienie o ankiecie wstępnej dla panelisty';
    case MailTemplateType.PANELIST_PAYOUT_REQUEST:
      return 'Wniosek o wypłatę środków';
    case MailTemplateType.PANELIST_ACTIVITY_REMINDER:
      return 'Przypomnienie o aktywności';
    case MailTemplateType.PANELIST_NEW_QUESTIONNAIRE:
      return 'Nowa ankieta płatna';
    case MailTemplateType.PANELIST_WARNING:
      return 'Ostrzeżenie dla panelisty';
    case MailTemplateType.PANELIST_SUSPENSION:
      return 'Zawieszenie panelisty';
    case MailTemplateType.PANELIST_BAN:
      return 'Blokada panelisty';
    case MailTemplateType.PANELIST_QUESTIONNAIRE_REMINDER:
      return 'Przypomnienie o ankiecie płatnej';
    case MailTemplateType.CLIENT_REGISTER:
      return 'Rejestracja klienta';
    case MailTemplateType.RESEARCH_STARTED:
      return 'Rozpoczęto badanie';
    case MailTemplateType.CLIENT_INVOICE:
      return 'Faktura dla klienta';
    case MailTemplateType.CLIENT_RESEARCH_SUMMARY:
      return 'Podsumowanie badania';
    case MailTemplateType.CLIENT_NEW_RESEARCH:
      return 'Nowe badanie';
    case MailTemplateType.CLIENT_ACTIVATED:
      return 'Klient aktywowany';
    case MailTemplateType.CLIENT_SOFT_LAUNCH_ENDED:
      return 'Zakończono etap Soft Launch';
    case MailTemplateType.NEW_TARGET_GROUP:
      return 'Nowa grupa targetowa';
    case MailTemplateType.PANELIST_INACTIVITY_WARNING:
      return 'Ostrzeżenie o braku aktywności';
    case MailTemplateType.PANELIST_INACTIVITY_DELETION:
      return 'Usunięcie panelisty z powodu braku aktywności';
    case MailTemplateType.FEEDBACK_RESPONSE:
      return 'Szablon odpowiedzi na zgłoszenie';
    default:
      return 'Nieznany szablon';
  }
}

export function isEmail(email: string) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}

export function toFixed2(number: number) {
  return number.toFixed(Number.isInteger(number) ? 0 : 2);
}

export const formatFullName = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (!firstName && !lastName) return '-';
  return firstName || lastName || '-';
};

export function getFileSrc(path?: string) {
  return `${import.meta.env.VITE_API_BASE_URL}${path}`;
}

export function isImageMimeType(mineType: string) {
  return (IMAGE_MIME_TYPE as string[]).includes(mineType);
}

export function detectCSVSeparator(line: string) {
  const separators = [',', ';', '\t', '|', ' '];
  let maxCount = 0;
  let detectedSeparator = ',';

  separators.forEach((sep) => {
    const count = line.split(sep).length - 1;
    if (count > maxCount) {
      maxCount = count;
      detectedSeparator = sep;
    }
  });

  return detectedSeparator;
}

export function formatOperationName(operationType: TransactionOperation) {
  switch (operationType) {
    case TransactionOperation.ADMIN_OPERATION:
      return 'Operacja administratora';
    case TransactionOperation.PREQUESTIONNAIRE:
      return 'Nagroda za ankietę wstępną';
    case TransactionOperation.PAYOUT:
      return 'Wypłata środków';
    case TransactionOperation.RESEARCH:
      return 'Nagroda za udział w badaniu';
    case TransactionOperation.REFERRAL_CODE:
      return 'Nagroda za program poleceń';
    case TransactionOperation.CONSTEST:
      return 'Uczestnictwo w konkursie';
  }
}
