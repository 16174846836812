import { LoginValues } from '@/pages/unauthenticated/Login';
import axios, { HttpStatusCode } from 'axios';

const httpClient = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json; charset=UTF-8',
	},
	validateStatus: (status) => status < HttpStatusCode.Unauthorized,
});
abstract class UnauthenticatedApi {
	static requestSMSCode = async ({
		email,
		password,
	}: Omit<LoginValues, 'smsCode'>) => {
		const response = await httpClient.post<{
			verification: boolean;
			code?: string;
		}>('auth/verify_data', {
			email,
			password,
		});

		return response;
	};

	static login = async ({ email, password, smsCode }: LoginValues) => {
		const response = await httpClient.post(`auth/login`, {
			email,
			password,
			smsCode,
		});

		return response;
	};

	static refreshToken = async (refresh_token: string) => {
		const response = await httpClient.post<{
			token: string;
			refresh_token: string;
		}>('auth/refresh_token', {
			refresh_token,
		});

		return response;
	};

	static requestResetPassword = async (email: string) => {
		try {
			const response = await httpClient.post(`reset_password/create_request`, {
				email,
				redirectUrl: `${import.meta.env.VITE_APP_URL}set-password`,
			});
			return response;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	};

	static resetPassword = async (token: string, password: string) => {
		try {
			const response = await httpClient.post(
				`reset_password/process`,
				{
					token,
					password,
				},
				{ validateStatus: () => true }
			);
			return response;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	};
}
export default UnauthenticatedApi;
