import { AxiosResponse } from 'axios';
import { omit, trim } from 'lodash';
import { QueryFunctionContext } from '@tanstack/react-query';
import {
	CreateManyResponse,
	HydraFormattedResponseData,
	HydraQueryOptions,
	HydraResponse,
	NotificationMessageType,
} from '@/types/types';
import { Panelist, UserStatus } from '@/types/user';
import { Cross, ResearchGroup, Research } from '@/types/research';
import { QueryKey } from '@/configs/queryKeys';
import { fileTypeToHeader } from '@/utils/utilities';
import { PanelistsFilters } from '@/pages/authenticated/panelists/Panelists';
import axios from 'axios';
import httpClient from './clients/httpClient';

export enum PayoutStatus {
	PENDING,
	PAID,
	CANCELLED,
}

export enum MessagePayoutPanelistType {
	PAID,
	NOT_NOTIFIED,
	SELECTED,
}

export interface PayoutRequest {
	id: string;
	panelist: Panelist | null;
	payoutAmount: number;
	status: PayoutStatus;
	createdAt: string;
}

export enum QuestionnaireStatus {
	PENDING,
	COMPLETED,
	QUOTAFULL,
	SCREENOUT,
	DROPOUT,
	TIMEOUT,
	EXCLUDED,
	EXPIRED,
	QUAL,
}

export interface PaidQuestionnaire {
	id: string;
	panelist: Panelist;
	research: Research;
	researchGroup: ResearchGroup;
	status: QuestionnaireStatus;
	startedAt: string;
	finishedAt: string;
	points: number;
}

export enum PanelistWarningType {
	HARD_WARN,
	SOFT_WARN,
	REACTIVATE,
}

export enum PanelistExportAvailability {
	ALL,
	AVAILABLE,
	UNAVAILABLE,
}

export enum TransactionType {
	INCOME,
	PAYOFF,
}

export enum TransactionOperation {
	PAYOUT,
	PREQUESTIONNAIRE,
	RESEARCH,
	ADMIN_OPERATION,
	REFERRAL_CODE,
	CONSTEST,
}

export interface PanelistTransaction {
	id: string;
	number: number;
	panelist: string;
	type: TransactionType;
	operation: TransactionOperation;
	amount: number;
	balance: number;
	createdAt: string;
	questionnaire?: PaidQuestionnaire;
}

abstract class PanelistApi {
	static getFilteredPaginated = async ({
		queryKey,
	}: QueryFunctionContext<
		[QueryKey, HydraQueryOptions, PanelistsFilters]
	>): Promise<HydraFormattedResponseData<Panelist>> => {
		const filters: Record<string, string> = {};
		if (queryKey[2].extraPoints) filters['extraPoints[gt]'] = '0';
		if (queryKey[2].duplicateFilter)
			filters[`duplicate_${queryKey[2].duplicateFilter}`] = 'true';

		switch (queryKey[2].notificationsEnabled) {
			case '0':
				break;
			case '1':
				filters['notificationsEnabled'] = 'true';
				break;
			case '2':
				filters['notificationsEnabled'] = 'false';
				break;
		}

		switch (queryKey[2].isDefaultFilled) {
			case '0':
				break;
			case '1':
				filters['isDefaultFilled'] = 'true';
				break;
			case '2':
				filters['isDefaultFilled'] = 'false';
				break;
		}

		const response = await httpClient.get<HydraResponse<Panelist>>(
			queryKey[0],
			{
				headers: { Accept: 'application/ld+json' },
				params: {
					page: queryKey[1].page,
					itemsPerPage: queryKey[1].sizePerPage,
					[`order[${queryKey[1].sort?.key}]`]: queryKey[1].sort?.order,
					simplesearch: trim(queryKey[1].search),
					...filters,
				},
			}
		);
		return {
			data: response.data['hydra:member'],
			total: response.data['hydra:totalItems'],
		};
	};

	static getPaginated = async ({
		queryKey,
	}: QueryFunctionContext<
		[QueryKey, HydraQueryOptions, Record<string, any>?]
	>): Promise<HydraFormattedResponseData<Panelist>> => {
		const response = await httpClient.get<HydraResponse<Panelist>>(
			queryKey[0],
			{
				headers: { Accept: 'application/ld+json' },
				params: {
					page: queryKey[1].page,
					itemsPerPage: queryKey[1].sizePerPage,
					[`order[${queryKey[1].sort?.key}]`]: queryKey[1].sort?.order,
					simplesearch: trim(queryKey[1].search),
				},
			}
		);
		return {
			data: response.data['hydra:member'],
			total: response.data['hydra:totalItems'],
		};
	};

	static getOne = async ({
		queryKey,
	}: QueryFunctionContext<string[]>): Promise<Panelist> => {
		return await (
			await httpClient.get(`panelists/${queryKey[1]}`)
		).data;
	};

	static delete = async (id: string) => {
		await httpClient.delete(`${QueryKey.panelists}/${id}`);
	};

	static massDelete = async (ids: string[]) => {
		await Promise.all(
			ids.map((id) => httpClient.delete(`${QueryKey.panelists}/${id}`))
		);
	};

	static createMany = async (
		panelists: Partial<Panelist>[]
	): Promise<CreateManyResponse> => {
		const client = axios.create({
			...httpClient.defaults,
			validateStatus: () => true,
		});

		const requests: Promise<AxiosResponse<Panelist>>[] = panelists.map(
			(panelist) => client.post(`panelists`, panelist)
		);

		const responsens = await Promise.all(requests);

		return panelists.reduce<CreateManyResponse>(
			(prev, current, index) =>
				responsens[index].status === 201
					? { ...prev, passed: [...prev.passed, responsens[index].data.email] }
					: {
							...prev,
							rejected: [...prev.rejected, current.email as string],
					  },
			{
				passed: [],
				rejected: [],
			}
		);
	};

	static update = async (payload: Partial<Panelist>) => {
		await httpClient.put(`panelists/${payload.id}`, omit(payload, ['id']));
	};

	static changeBalance = async (payload: {
		balance: number;
		id: string;
	}): Promise<void> => {
		await httpClient.patch(`panelists/${payload.id}/change_balance`, {
			balance: payload.balance,
		});
	};

	static changeStatus = async ({
		id,
		status,
	}: {
		id: string;
		status: UserStatus;
	}): Promise<void> => {
		await httpClient.patch(`panelists/${id}/change_status`, {
			status,
		});
	};

	static getPaginatedPayouts = async ({
		queryKey,
	}: QueryFunctionContext<[string, HydraQueryOptions]>): Promise<
		HydraFormattedResponseData<PayoutRequest>
	> => {
		const response = await httpClient.get<HydraResponse<PayoutRequest>>(
			queryKey[0],
			{
				headers: { Accept: 'application/ld+json' },
				params: {
					page: queryKey[1].page,
					itemsPerPage: queryKey[1].sizePerPage,
					[`order[${queryKey[1].sort?.key}]`]: queryKey[1].sort?.order,
					simplesearch: trim(queryKey[1].search),
				},
			}
		);
		return {
			data: response.data['hydra:member'],
			total: response.data['hydra:totalItems'],
		};
	};

	static getPendingPayouts = async () => {
		const response = await httpClient.post<Blob>(
			`${QueryKey.payoutRequests}/transfers`,
			{},
			{
				headers: {
					Accept: fileTypeToHeader('csv'),
				},
				responseType: 'blob',
			}
		);

		return response.data;
	};

	static changePayoutStatus = async ({
		id,
		status,
	}: {
		id: string;
		status: PayoutStatus;
	}) => {
		await httpClient.patch(`${QueryKey.payoutRequests}/${id}/change_status`, {
			status,
		});
	};

	static getQuestionnaires = async ({
		queryKey,
	}: QueryFunctionContext<string[]>) => {
		const response = await httpClient.get<PaidQuestionnaire[]>(queryKey[0], {
			params: {
				panelist: queryKey[1],
				pagination: false,
			},
		});

		return response.data;
	};

	static massChangePayoutStatuses = async ({
		payoutRequests,
		status,
	}: {
		payoutRequests: string[];
		status: PayoutStatus;
	}) => {
		await httpClient.post(`${QueryKey.payoutRequests}/bulk_update`, {
			status,
			payoutRequests,
		});
	};

	static getPanelistPayoutRequests = async ({
		queryKey,
	}: QueryFunctionContext<string[]>): Promise<PayoutRequest[]> => {
		return await (
			await httpClient.get(queryKey[0], {
				params: {
					panelist: queryKey[1],
					pagination: false,
				},
			})
		).data;
	};

	static emitWarnings = async ({
		panelists,
		type,
	}: {
		panelists: string[];
		type: PanelistWarningType;
	}) => {
		const response = await httpClient.post<{
			ban?: Panelist[];
			suspension?: Panelist[];
			warning?: Panelist[];
			reactivation?: Panelist[];
		}>(`panelist_warning`, {
			panelists,
			type,
		});

		return response.data;
	};

	static generateFederalPayoutsData = async (data: {
		month: number;
		year: number;
	}) => {
		const response = await httpClient.post<Blob>(
			`${QueryKey.payoutRequests}/export`,
			data,
			{
				headers: {
					Accept: fileTypeToHeader('xlsx'),
				},
				responseType: 'blob',
			}
		);

		return response.data;
	};

	static exportPanelistData = async ({
		fields,
		crosses,
		panelists,
		preQuestionnaireProgress,
		availableStatus,
	}: {
		fields: (keyof Panelist)[];
		crosses: Cross[];
		panelists: string[];
		preQuestionnaireProgress: [number, number];
		availableStatus: PanelistExportAvailability;
	}) => {
		const response = await httpClient.post<Blob>(
			'export_panelists_data_by_cross',
			{
				fields,
				crosses: crosses.map((cross) => ({
					...cross,
					answers: cross.answers.map((answer) => answer.id),
				})),
				panelists,
				preQuestionnaireProgress,
				availableStatus,
			},
			{
				headers: {
					Accept: fileTypeToHeader('csv'),
				},
				responseType: 'blob',
			}
		);

		return response.data;
	};

	static sendMessage = async (payload: {
		panelists: string[];
		messageType: NotificationMessageType;
		title: string;
		message: string;
	}) => {
		const response = await httpClient.post<{
			panelists: string[];
		}>(`notification_message`, payload);

		return response.data.panelists;
	};

	static sendMessagePayout = async (payload: {
		panelistsType: MessagePayoutPanelistType;
		messageType: NotificationMessageType;
		title: string;
		message: string;
	}) => {
		const response = await httpClient.post<{
			panelists: string[];
		}>('notification_message/payout', payload);

		return response.data.panelists;
	};

	static getPanelistTransactions = async ({
		queryKey,
	}: QueryFunctionContext<[QueryKey, HydraQueryOptions, string]>): Promise<
		HydraFormattedResponseData<PanelistTransaction>
	> => {
		const response = await httpClient.get<HydraResponse<PanelistTransaction>>(
			queryKey[0],
			{
				headers: { Accept: 'application/ld+json' },
				params: {
					page: queryKey[1].page,
					itemsPerPage: queryKey[1].sizePerPage,
					panelist: queryKey[2],
					[`order[${queryKey[1].sort?.key}]`]: queryKey[1].sort?.order,
					simplesearch: trim(queryKey[1].search),
				},
			}
		);
		return {
			data: response.data['hydra:member'],
			total: response.data['hydra:totalItems'],
		};
	};
}
export default PanelistApi;
