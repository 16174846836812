export const QueryKey = {
	systemStats: 'system_stats',
	globalSettings: 'global_settings',
	affiliations: 'affiliations',
	affiliationsDetailed: 'affiliations_detailed',
	admins: 'admins',
	panelists: 'panelists',
	pollsters: 'pollsters',
	clients: 'clients',
	regions: 'regions',
	contents: 'contents',
	pricings: 'pricings',
	feedback: 'feedback',
	research: 'research',
	researchGroups: 'research_groups',
	payoutRequests: 'payout_requests',
	questionTemplates: 'question_templates',
	answerTemplates: 'answer_templates',
	questionnaires: 'questionnaires',
	questionnaireTemplates: 'pre_questionnaire_module_templates',
	mailTemplates: 'mail_templates',
	contests: 'contests',
	contestQuestions: 'contest_questions',
	contestAnswers: 'contest_answers',
	panelistTransactions: 'panelist_transactions',
} as const;

export type QueryKey = (typeof QueryKey)[keyof typeof QueryKey];
