export enum AppPaths {
	LOGIN = 'login',
	RESET_PASSWORD = 'reset-password',
	SET_PASSWORD = 'set-password',

	CONTENTS = 'contents',
	FEEDBACK = 'feedback',

	ADMINS = 'admins',
	CLIENTS = 'clients',
	PANELISTS = 'panelists',
	PRE_QUESTIONNAIRES = 'pre-questionnaires',

	MAIL_TEMPLATES = 'mail-templates',
	GLOBAL_SETTINGS = 'global-settings',
	CONTESTS = 'contests',
	RESEARCHES = 'researches',
}
