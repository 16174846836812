import { ReactNode } from 'react';
import {
	DefaultOptions,
	Mutation,
	MutationCache,
	Query,
	QueryCache,
	QueryClient,
	QueryClientProvider,
	QueryKey,
} from '@tanstack/react-query';
import { useTypedDispatch } from '@/store/hooks';
import { setErrorCode, setLoading } from '@/store/slices/appSlice';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import APP_CONFIG from '@/configs/appConfig';

export const QueryProvider = ({ children }: { children: ReactNode }) => {
	const dispatch = useTypedDispatch();

	const handleQueryError = (
		error: any,
		query: Query<unknown, unknown, unknown, QueryKey>
	) => {
		if (import.meta.env.DEV) console.error(error);

		if (query.meta?.ignoreErrors) return;

		dispatch(
			setErrorCode(!isNaN(Number(error.message)) ? error.message : '500')
		);
	};

	const handleMutationError = (
		error: any,
		variables: unknown,
		context: unknown,
		mutation: Mutation<unknown, unknown, unknown>
	) => {
		if (import.meta.env.DEV) console.error(error);

		if (mutation.meta?.ignoreErrors) return;

		if (Number(error.message) >= 500 || isNaN(Number(error.message)))
			dispatch(
				setErrorCode(!isNaN(Number(error.message)) ? error.message : '500')
			);
	};

	const defaultOptions: DefaultOptions = {
		queries: {
			staleTime: APP_CONFIG.QUERY_STALE_TIME,
			cacheTime: APP_CONFIG.QUERY_CACHE_TIME,
			retry: APP_CONFIG.QUERY_RETRY,
		},
		mutations: {
			retry: false,
		},
	};

	const queryCache = new QueryCache({
		onError: handleQueryError,
		onSuccess: (_, { meta }) => {
			if (meta?.persistLoading) return;
			dispatch(setLoading(false));
		},
	});

	const mutationCache = new MutationCache({
		onError: handleMutationError,
	});

	const queryClient = new QueryClient({
		defaultOptions,
		queryCache,
		mutationCache,
	});

	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools position="bottom-right" />
		</QueryClientProvider>
	);
};
