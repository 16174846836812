export interface AppError {
	insideMessage: string;
	insideExceptionCode: number;
	message: string;
	code: string;
}

export enum ErrorCodes {
	USER_NOT_FOUND = 100,
	INVALID_EMAIL = 101,
	DUPLICATED_EMAIL = 102,
	INVALID_PASSWORD = 103,
	INVALID_CURRENT_PASSWORD = 104,
	INACTIVE_USER = 105,
	SMS_SENDING_ERROR = 112,
	EXPIRED_SMS_CODE = 113,
	USED_SMS_CODE = 114,
	INVALID_SMS_CODE = 115,
	START_HIGHER_THAN_END = 120,
	OVERLAPPING_VALUES = 121,
	INVALID_URL = 130,
	INVALID_PESEL = 134,
	INVALID_NIP = 135,
	DUPLICATED_REGION = 140,
	PANELIST_NO_PHONE = 145,
	PANELIST_NO_EMAIL = 146,
	NOT_ENOUGHT_UNIQUE_LINKS_AVAILABLE = 147,
	DUPLICATED_TEMPLATE_NAME = 150,
	MISSING_REGIONS = 160,
}

export function mapCodesToMessage(code?: ErrorCodes) {
	switch (code) {
		case ErrorCodes.USER_NOT_FOUND:
			return 'Taki email nie istnieje w systemie';
		case ErrorCodes.INVALID_EMAIL:
			return '';
		case ErrorCodes.DUPLICATED_EMAIL:
			return 'Podany adres email został już wykorzystany';
		case ErrorCodes.INVALID_PASSWORD:
			return 'Hasło musi mieć co najmniej 8 znaków, małą i wielką literę, cyfrę oraz znak specjalny';
		case ErrorCodes.INVALID_CURRENT_PASSWORD:
			return 'Hasło jest nieprawidłowe!';
		case ErrorCodes.INACTIVE_USER:
			return 'Użytkownik nie został aktywowany';
		case ErrorCodes.SMS_SENDING_ERROR:
			return 'Nie możemy wysłać wiadomości SMS!';
		case ErrorCodes.EXPIRED_SMS_CODE:
			return 'Podany kod SMS stracił ważność!';
		case ErrorCodes.USED_SMS_CODE:
			return 'Podany kod SMS został już użyty!';
		case ErrorCodes.INVALID_SMS_CODE:
			return 'Podany kod SMS jest nieprawidłowy!';
		case ErrorCodes.DUPLICATED_TEMPLATE_NAME:
			return 'W bazie istnieje już moduł o takiej nazwie!';
		case ErrorCodes.START_HIGHER_THAN_END:
			return 'Początek zakresu nie może być większy niż jego koniec!';
		case ErrorCodes.OVERLAPPING_VALUES:
			return 'Zakresy nachodzą na siebie!';
		case ErrorCodes.INVALID_PESEL:
			return 'Podany nr pesel jest niepoprawny!';
		case ErrorCodes.INVALID_NIP:
			return 'Podany NIP jest niepoprawny!';
		case ErrorCodes.DUPLICATED_REGION:
			return 'Ten region już istnieje w bazie!';
		case ErrorCodes.MISSING_REGIONS:
			return 'Niektóre z żadanych regionów nie istnieją w bazie!';
		case ErrorCodes.PANELIST_NO_PHONE:
			return 'Wybrany panelista nie posiada numeru telefonu!';
		case ErrorCodes.PANELIST_NO_EMAIL:
			return 'Wybrany panelista nie posiada adresu email!';
		case ErrorCodes.INVALID_URL:
			return 'Podany adres URL jest nieprawidłowy!';
		case ErrorCodes.NOT_ENOUGHT_UNIQUE_LINKS_AVAILABLE:
			return 'Zbyt mało wolnych unikalnych linków!';
		default:
			return 'Coś poszło nie tak! Spróbuj ponownie później';
	}
}
