import { QueryFunctionContext } from '@tanstack/react-query';
import { omit, pick } from 'lodash';
import { Language } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import { PreQuestionnaireRemovedList } from '@/pages/authenticated/preQuestionnaires/EditPreQuestionnaire';
import httpClient from './clients/httpClient';

export interface PreQuestionnaireTempalte {
	id: string;
	name: string;
	customOrder: number;
	reward: number;
	color: string;
	isRequired: boolean;
	isDefault: boolean;
	questionTemplates: QuestionTemplate[];
	translations: Record<Language, { name: string }>;
}

export interface QuestionTemplate {
	id: string;
	preQuestionnaireModuleTemplate: string;
	type: QuestionType;
	questionOrder: number;
	answerTemplates: AnswerTemplate[];
	relatedAnswerTemplate?: AnswerTemplate;
	content?: string;
	isNew?: boolean;
	description?: string;
	translations: Record<Language, { content: string; description: string }>;
}

export interface AnswerTemplate {
	id: string;
	questionTemplate: string;
	answerOrder: number;
	relatedQuestionTemplates: QuestionTemplate[];
	content?: string;
	isNew?: boolean;
	translations: Record<Language, { content: string }>;
}

export enum QuestionType {
	MULTISELECT,
	RADIO_TILES,
	SELECT,
	SLIDER,
	DATE,
	RADIO,
	CHECKBOX,
}

abstract class PreQuestionnaireApi {
	static getAll = async ({ queryKey }: QueryFunctionContext<[string]>) => {
		const response = await httpClient.get<PreQuestionnaireTempalte[]>(
			queryKey[0],
			{
				params: {
					pagination: false,
				},
			}
		);

		return response.data.sort((a, b) => a.customOrder - b.customOrder);
	};

	static getOne = async ({
		queryKey,
	}: QueryFunctionContext<[string, string]>) => {
		return (
			await httpClient.get<PreQuestionnaireTempalte>(
				`${queryKey[0]}/${queryKey[1]}`
			)
		).data;
	};

	static delete = async (id: string) => {
		await httpClient.delete(`${QueryKey.questionnaireTemplates}/${id}`);
	};

	static reorder = async (preQuestionnaires: PreQuestionnaireTempalte[]) => {
		await httpClient.post<PreQuestionnaireTempalte[]>(
			`${QueryKey.questionnaireTemplates}/reorder`,
			{
				preQuestionnaires: preQuestionnaires.map((preQuestionnaire) =>
					pick(preQuestionnaire, ['id', 'customOrder'])
				),
			}
		);
	};

	static createTemplate = async (questionnaire: PreQuestionnaireTempalte) => {
		const questionnaireResponse =
			await httpClient.post<PreQuestionnaireTempalte>(
				QueryKey.questionnaireTemplates,
				{
					...omit(questionnaire, 'id'),
					questionTemplates: [],
				}
			);

		const newQuestionnaire = questionnaireResponse.data;

		const createQuestion = async (
			question: QuestionTemplate,
			answerId?: string
		) => {
			const questionResponse = await httpClient.post<QuestionTemplate>(
				QueryKey.questionTemplates,
				{
					...omit(question, 'id'),
					type: Number(question.type),
					preQuestionnaireModuleTemplate: newQuestionnaire.id,
					relatedAnswerTemplate: answerId,
					answerTemplates: [],
				}
			);

			const newQuestion = questionResponse.data;

			for (const answer of question.answerTemplates) {
				const answerResponse = await httpClient.post<AnswerTemplate>(
					QueryKey.answerTemplates,
					{
						...omit(answer, 'id'),
						questionTemplate: newQuestion.id,
						relatedQuestionTemplates: [],
					}
				);

				const newAnswer = answerResponse.data;

				await Promise.all(
					answer.relatedQuestionTemplates.map((relatedQuestion) =>
						createQuestion(relatedQuestion, newAnswer.id)
					)
				);
			}
		};

		await Promise.all(
			questionnaire.questionTemplates.map((question) =>
				createQuestion(question)
			)
		);
	};

	static toggleIsRequired = async (questionnaire: PreQuestionnaireTempalte) => {
		await httpClient.patch(
			`${QueryKey.questionnaireTemplates}/${questionnaire.id}/change_required`,
			{
				isRequired: !questionnaire.isRequired,
			}
		);
	};

	static updateQestionnaire = async ({
		questionnaireTemplate,
		removed,
	}: {
		questionnaireTemplate: PreQuestionnaireTempalte;
		removed: PreQuestionnaireRemovedList;
	}) => {
		await httpClient.put(
			`${QueryKey.questionnaireTemplates}/${questionnaireTemplate.id}`,
			pick(questionnaireTemplate, ['translations', 'reward', 'color'])
		);

		const removedQuestionsPromises = removed.questions.map((id) =>
			httpClient.delete(`${QueryKey.questionTemplates}/${id}`)
		);

		const removedAnswersPromises = removed.answers.map((id) =>
			httpClient.delete(`${QueryKey.answerTemplates}/${id}`)
		);

		await Promise.all([removedQuestionsPromises, removedAnswersPromises]);

		for (const question of questionnaireTemplate.questionTemplates) {
			await questionTree(question, questionnaireTemplate.id);
		}
	};
}
export default PreQuestionnaireApi;

async function questionTree(
	question: QuestionTemplate,
	teamplateId: string,
	answerId?: string
) {
	if (question.isNew)
		return await createQuestionTree(question, teamplateId, answerId);

	await httpClient.put<QuestionTemplate>(
		`${QueryKey.questionTemplates}/${question.id}`,
		pick(question, ['translations', 'questionOrder', 'type'])
	);

	for (const answer of question.answerTemplates) {
		if (answer.isNew) {
			const answerResponse = await httpClient.post<AnswerTemplate>(
				QueryKey.answerTemplates,
				{
					...pick(answer, ['translations', 'answerOrder']),
					questionTemplate: question.id,
					relatedQuestionTemplates: [],
				}
			);

			for (const relatedQuestion of answer.relatedQuestionTemplates) {
				await createQuestionTree(
					relatedQuestion,
					teamplateId,
					answerResponse.data.id
				);
			}

			continue;
		}

		await httpClient.put<AnswerTemplate>(
			`${QueryKey.answerTemplates}/${answer.id}`,
			pick(answer, ['translations', 'answerOrder'])
		);

		for (const relatedQuestion of answer.relatedQuestionTemplates) {
			if (relatedQuestion.isNew)
				await createQuestionTree(relatedQuestion, teamplateId, answer.id);
			else await questionTree(relatedQuestion, teamplateId, answer.id);
		}
	}
}

async function createQuestionTree(
	question: QuestionTemplate,
	teamplateId: string,
	answerId?: string
) {
	const questonResponse = await httpClient.post<QuestionTemplate>(
		QueryKey.questionTemplates,
		{
			...pick(question, ['translations', 'questionOrder', 'type']),
			preQuestionnaireModuleTemplate: teamplateId,
			answerTemplates: [],
			relatedAnswerTemplate: answerId,
		}
	);

	for (const answer of question.answerTemplates) {
		const answerResponse = await httpClient.post<AnswerTemplate>(
			QueryKey.answerTemplates,
			{
				translations: answer.translations,
				questionTemplate: questonResponse.data.id,
			}
		);

		for (const relatedQuestion of answer.relatedQuestionTemplates) {
			await createQuestionTree(
				relatedQuestion,
				teamplateId,
				answerResponse.data.id
			);
		}
	}
}

export function mapQuestionTypesToNames(type: QuestionType) {
	switch (type) {
		case QuestionType.MULTISELECT:
			return 'Multiselect';
		case QuestionType.RADIO_TILES:
			return 'Kafle radio';
		case QuestionType.SELECT:
			return 'Select';
		case QuestionType.SLIDER:
			return 'Slajder';
		case QuestionType.DATE:
			return 'Data';
		case QuestionType.RADIO:
			return 'Radio';
		case QuestionType.CHECKBOX:
			return 'Checkbox';
	}
}
