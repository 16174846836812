import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Alert,
	Card,
	CardBody,
	Container,
	FormFeedback,
	Input,
	Label,
	Form,
	CardTitle,
} from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import logoNPB from '@/assets/images/logo-NPB.png';

import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import { mapCodesToMessage, ErrorCodes } from '@/types/ErrorCodes';
import { validatePassword } from '@/utils/utilities';
import { LoadingOverlay } from '@mantine/core';

const initialValues = {
	password: '',
	repeatPassword: '',
};

const submitSuccessMessage =
	'Hasło zostało zresetowane. Wróć do panelu logowania i zaloguj się za pomocą nowego hasła.';

export const SetNewPassword: React.FC = () => {
	const navigate = useNavigate();

	const { token } = useParams();

	useEffect(() => {
		if (!token) navigate(`/login`);
	}, [navigate, token]);

	const [success, setSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues,
		validate: {
			password: (value: string, values: Partial<typeof initialValues>) => {
				if (value !== values.repeatPassword)
					return 'Hasła muszą być takie same';
				if (!validatePassword(value))
					return mapCodesToMessage(ErrorCodes.INVALID_PASSWORD);
				return null;
			},
			repeatPassword: (
				value: string,
				values: Partial<typeof initialValues>
			) => {
				if (value !== values.password) return 'Hasła muszą być takie same';
				if (!validatePassword(value))
					return mapCodesToMessage(ErrorCodes.INVALID_PASSWORD);
				return null;
			},
		},
	});

	const handleSubmit = async () => {
		setLoading(true);
		const response = await UnauthenticatedApi.resetPassword(
			token || '',
			form.values.password
		);
		setLoading(false);
		form.reset();

		if (response?.status && response?.status < 401) return setSuccess(true);

		setErrorMessage(mapCodesToMessage(response?.data?.insideExceptionCode));
	};

	return (
		<div className="account-pages">
			<Container>
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={5}>
						<Card className="overflow-hidden">
							<LoadingOverlay visible={loading} />
							<div className="logo-bar">
								<div>
									<img
										src={logoNPB}
										alt="company logo"
										className="img-fluid header-logo"
									/>
								</div>
							</div>
							<CardBody>
								<div className="p-2">
									<CardTitle>Utwórz nowe hasło</CardTitle>
									{!!errorMessage && (
										<Alert color="danger">{errorMessage}</Alert>
									)}
									{success ? (
										<Alert color="success">{submitSuccessMessage}</Alert>
									) : (
										<p className="card-text">
											Lorem impsum zasady tworzenia hasła: some quick example
											text to build on the card title and make up the bulk of
											the card's content.
										</p>
									)}
									<Form
										className="form-horizontal"
										onSubmit={form.onSubmit(handleSubmit)}
									>
										<div className="mb-3">
											<Label className="form-label">Hasło</Label>
											<Input
												required
												type="password"
												className="form-control"
												placeholder="Wpisz hasło"
												{...form.getInputProps('password')}
												invalid={!!form.errors.password}
												valid={success}
												disabled={success}
											/>
											<FormFeedback type="invalid">
												{form.errors.password}
											</FormFeedback>
											<FormFeedback valid type="valid">
												Podano prawidłowe hasło
											</FormFeedback>
										</div>
										<div className="mb-3">
											<Label className="form-label">Powtórz hasło</Label>
											<Input
												required
												type="password"
												className="form-control"
												placeholder="Wpisz hasło"
												{...form.getInputProps('repeatPassword')}
												invalid={!!form.errors.repeatPassword}
												valid={success}
												disabled={success}
											/>
											<FormFeedback type="invalid">
												{form.errors.repeatPassword}
											</FormFeedback>
											<FormFeedback valid type="valid">
												Podano prawidłowe hasło
											</FormFeedback>
										</div>
										<Row>
											<Col className="">
												<Link to="/login">
													<button
														className="btn btn-secondary w-md "
														type="button"
													>
														Wróć
													</button>
												</Link>
											</Col>
											<Col className="text-end">
												{!success && (
													<button
														className="btn btn-primary w-md "
														type="submit"
													>
														Resetuj
													</button>
												)}
											</Col>
										</Row>
									</Form>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
